<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-breadcrumb>
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>人员管理 /</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
                  <div class="topCard">
                <div class="item" v-for="item,index in companyList" :key="index"  @click="searchCompany(item)">
                    <div class="num">{{ item.userCount}}<span class="unit">人</span></div>
                    <div class="title">{{item.deptName}}</div>
                </div>
            </div>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <!-- <el-button type="primary" size="small">导入</el-button>
          <el-button type="primary" size="small">导出</el-button> -->
        </el-col>
        <el-col>
          <div class="flex align-center justify-end header-search-box">
             <el-select
              style="margin-right: 8px"
              v-model="queryInfo.condition.companyCode"
              filterable
              clearable
              placeholder="选择公司"
            >
              <el-option
                v-for="item in companyOptions"
                :key="item.deptCode"
                :label="item.deptName"
                :value="item.deptCode"
              >
              </el-option>
            </el-select>
              <el-select

              style="margin-right: 8px"
              v-model="queryInfo.condition.deptCode"
              filterable
              clearable
              placeholder="选择部门"
            >
              <el-option
                v-for="item in deptOptions"
                :key="item.deptCode"
                :label="item.deptName"
                :value="item.deptCode"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="queryInfo.condition.status"
              filterable
              clearable
              placeholder="选择状态"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              placeholder="姓名/工号/员工账号/邮箱"
              clearable
              v-model="queryInfo.condition.keyword"
              class="header-search-input ml10"
            >
              <el-button
                slot="append"
                @click="getUserData"
                icon="el-icon-search"
                class="textblue searchBtn"
              ></el-button>
            </el-input>
             <el-button type="primary" @click="gotoDetail('')"  class="add-column-btn bbox ml10"
            ><i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
          </div>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        height="calc(100vh - 350px)"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
      <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column width="60" show-overflow-tooltip label="姓名" align="center" min-width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.familyName }}{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="员工账号"
          prop="loginName"
          align="center"
          width="110"
        >
        </el-table-column>
        <!-- <el-table-column  align="center" label="用户类型">
          <template slot-scope="{row}">
            <span v-if="row.userType==1">运营商</span>
            <span v-if="row.userType==2">施工方</span>
            <span v-if="row.userType==3">投资方</span>
          </template>
        </el-table-column> -->
        <el-table-column min-width="140px" label="所属公司名称" align="center" prop="companyName"></el-table-column>
        <el-table-column width="90" label="部门名称" align="center" prop="deptName"></el-table-column>
        <el-table-column width="120" label="岗位名称" align="center" prop="postName"></el-table-column>
        <el-table-column width="160px" label="创建时间" align="center" prop="createTime"></el-table-column>




        <!-- <el-table-column
          label="状态"
          prop="status"
          sortable="custom"
          align="center"
          min-width="140"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.status == "1"
                ? "在职"
                : scope.row.status == "2"
                ? "离职"
                : scope.row.status == "3"
                ? "产期休假"
                : scope.row.status == "4"
                ? "代理"
                : scope.row.status == "5"
                ? "供应商"
                : "外部"
            }}</span>
          </template>
        </el-table-column> -->

        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="180"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id, scope.row.userCode)"
              ></i>
            </el-tooltip>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip> -->
            <el-tooltip
              class="item"
              effect="dark"
              content="重置密码"
              placement="bottom"
            >
              <i class="el-icon-lock textblue f16 ml20" @click="reset(1)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBo">
        <!-- 新增按钮 -->
        <!-- <div class="handle-btn wp100" @click="gotoDetail('')">
          <el-button plain class="add-column-btn bbox"
            ><i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </div> -->
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      companyList:[],
      deptOptions:[],
      companyOptions:[],
      addForm: {
        trueName: "",
        loginName: "",
        phone: "",
        password: "",
      },
      options: [
        {
          value: "1",
          label: "在职",
        },
        {
          value: "2",
          label: "离职",
        },
        // {
        //   value: "产期休假",
        //   label: "产期休假",
        // },
        // {
        //   value: "代理",
        //   label: "代理",
        // },
        // {
        //   value: "供应商",
        //   label: "供应商",
        // },
        // {
        //   value: "外部",
        //   label: "外部",
        // },
      ],
      value: "",
      searchInput: "",
      tableData: [],
      loading: true,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
        condition:{
          companyCode:"",
          deptCode:"",
          objectCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode
        }
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      dialogFormVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: "",
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      formLabelWidth: "100",
    };
  },
  watch:{
    "queryInfo.condition.companyCode":{
      handler(v){
        if(v){
         this.getDeptOptions()
        }
      }
    },
  },
  created() {
    console.log(JSON.parse(localStorage.getItem('userInfo')).userInfo.userInfo,301);
    this.getCompanyList()
    this.getUserData();
    this.getCompanyOptions()
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    async searchCompany(item){
      const copiedObj = _.cloneDeep(this.queryInfo);
      copiedObj.condition.companyCode=item.deptCode
      var that = this;
      that.$http.post("/userInfo/list", copiedObj).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.tableData = response.data.data.data;
        }
      });


    },
  async  getCompanyList(){
    let res=await this.$http.post("/engineeringDept/queryCompanyUserNumList",{groupCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode})
    if(res.data.code==200){
      this.companyList=res.data.data
    }else{
      this.$message.error(res.data.message)
    }
   },
    async getDeptOptions(){
      let res=await this.$http.post('engineeringDept/queryList',
      {parentCode:this.queryInfo.condition.companyCode,type:1,groupCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode})
      if(res.data.code==200){
        this.deptOptions=res.data.data
        this.queryInfo.condition.deptCode=''
      }else{
        this.$message.error(res.data.message)
      }
    },
   async getCompanyOptions(){
    let res=await this.$http.post('engineeringDept/queryCompanyList',{groupCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode})
    if(res.data.code==200){
      this.companyOptions=res.data.data
    }else{
      this.$message.error(res.data.message)
    }
   },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getUserData();
    },

    // 监听排序
    sortChange() {},

    //跳转到详情
    gotoDetail(id, userCode) {
      this.$router.push({
        path: "/employeeManagement/detail",
        query: { id: id, userCode: userCode },
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        // return this.$message.info("已取消删除");
        return this.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: false,
        });
      } else {
        var that = this;
        that.$http.delete("/userInfo/" + id).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true,
          });
          that.getUserData();
        });
      }
    },
    // 重置密码
    async reset(id) {
      var that = this;
      const confirmResult = await this.$confirm("请确认重置密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      console.log(confirmResult)
      console.log('3333333333333333')
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        // return this.$message.info("已取消删除");
        return this.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: false,
        });
      }else{
        that.$http.post("/userInfo/list", this.queryInfo).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.tableData = response.data.data.data;
        }
      });
        that.$http.post("/userInfo/resetPwd", {id}).then(function (response) {
        if (response.data.code == 200) {
          that.$message.success('重置成功')
        }
      });
      }
    },

    // 获取人员列表
    getUserData() {
      var that = this;
      that.$http.post("/userInfo/list", this.queryInfo).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.tableData = response.data.data.data;
        }
      });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.topCard{
    display: flex;


    margin-bottom: 10px;
    .item{
        // width: calc((100vw - 60px) / 3);
        width: 150px;
          height: 90px;
          padding: 0 10px;
            border-radius: 10px;
            display: flex;
            margin-left:20px ;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .num{
                font-size: 22px;
                 font-weight: 600;
                 color: #d9001b;
                 .unit{
                  font-size: 14px;
                 }

                }
            .title{
                color: #333;
                font-size: 14px;
                    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
    word-break: break-all;
            }

    }
    .item:nth-child(1) {
      margin-left:0px ;

}

    .item:nth-child(3n+1) {
  background-color: #F1FDDD;

}

.item:nth-child(3n+2) {
  background-color: #FEF1E1;

}

.item:nth-child(3n+3) {
  background-color: #FADDE0;

}
}
/deep/ .el-table{
  td{
    padding: 3px 0;
  }
}
</style>
